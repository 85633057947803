import * as PIXI from 'pixi.js';
import logo from './logo.svg';
import './App.css';
import {debounce} from "lodash";

var carrotTex = PIXI.Texture.from('/img/fireball.png');

function App() {
  let isStarted = false;
// Based somewhat on this article by Spicy Yoghurt
// URL for further reading: https://spicyyoghurt.com/tutorials/html5-javascript-game-development/collision-detection-physics
  const app = new PIXI.Application({ background: '#111', resizeTo: window });

  document.body.appendChild(app.view);

// Options for how objects interact
// How fast the red square moves
  const movementSpeed = 0.1;

// Strength of the impulse push between two objects
  const impulsePower = 5;

// Test For Hit
// A basic AABB check between two different squares
  function testForAABB(object1, object2)
  {
    const bounds1 = object1.getBounds();
    const bounds2 = object2.getBounds();

    return bounds1.x < bounds2.x + bounds2.width
      && bounds1.x + bounds1.width > bounds2.x
      && bounds1.y < bounds2.y + bounds2.height
      && bounds1.y + bounds1.height > bounds2.y;
  }

// Calculates the results of a collision, allowing us to give an impulse that
// shoves objects apart
  function collisionResponse(object1, object2)
  {
    if (!object1 || !object2)
    {
      return new PIXI.Point(0);
    }
    console.log('collisionResponse')
    object1.tint = Math.random() * 0xFFFFFF;

    const vCollision = new PIXI.Point(
      object2.x - object1.x,
      object2.y - object1.y,
    );

    const distance = Math.sqrt(
      (object2.x - object1.x) * (object2.x - object1.x)
      + (object2.y - object1.y) * (object2.y - object1.y),
    );

    const vCollisionNorm = new PIXI.Point(
      vCollision.x / distance,
      vCollision.y / distance,
    );

    const vRelativeVelocity = new PIXI.Point(
      object1.acceleration.x - object2.acceleration.x,
      object1.acceleration.y - object2.acceleration.y,
    );

    const speed = vRelativeVelocity.x * vCollisionNorm.x
      + vRelativeVelocity.y * vCollisionNorm.y;

    const impulse = impulsePower * speed / (object1.mass + object2.mass);

    return new PIXI.Point(
      impulse * vCollisionNorm.x,
      impulse * vCollisionNorm.y,
    );
  }

// Calculate the distance between two given points
  function distanceBetweenTwoPoints(p1, p2)
  {
    const a = p1.x - p2.x;
    const b = p1.y - p2.y;

    return Math.hypot(a, b);
  }

// The green square we will knock about
  const greenSquare = PIXI.Sprite.from("/img/mushmom.png");

  greenSquare.position.set((app.screen.width - 200) / 2, ((app.screen.height - 100) / 2) - 200);
  greenSquare.width = 200;
  greenSquare.height = 200;
  greenSquare.acceleration = new PIXI.Point(0);
  greenSquare.mass = 3;

// The square you move around
  const redSquare = PIXI.Sprite.from("/img/dragon_1.png");
  redSquare.position.set((app.screen.width - 100) / 2, (app.screen.height - 100) / 2 + 300);
  redSquare.width = 100;
  redSquare.height = 100;
  redSquare.acceleration = new PIXI.Point(0);
  redSquare.mass = 1;

  const mouseCoords = { x: 0, y: 0 };

  app.stage.eventMode = 'static';
  app.stage.hitArea = app.screen;

  const shootEvent = () => {
    setTimeout(() => {
      shoot(0, {
        x: redSquare.x,
        y: redSquare.y
      });
      shootEvent()
    }, 100);
  }
  shootEvent();
  app.stage.on('touchmove', (event) => {
    if(!isStarted){
      isStarted = true;
    }
    mouseCoords.x = event.data.global.x;
    mouseCoords.y = event.data.global.y;
  });
  app.stage.on('mousemove', (event) => {
    if(!isStarted){
      isStarted = true;
    }
    mouseCoords.x = event.data.global.x;
    mouseCoords.y = event.data.global.y;
  })

// Listen for animate update
  app.ticker.add((delta) =>
  {
    for(var b=bullets.length-1;b>=0;b--){
      bullets[b].position.x -= Math.sin(bullets[b].rotation)*bulletSpeed;
      bullets[b].position.y -= Math.cos(bullets[b].rotation)*bulletSpeed;
    }

    // Applied deacceleration for both squares, done by reducing the
    // acceleration by 0.01% of the acceleration every loop
    //redSquare.acceleration.set(redSquare.acceleration.x * 0.99, redSquare.acceleration.y * 0.99);
    greenSquare.acceleration.set(greenSquare.acceleration.x * 0.99, greenSquare.acceleration.y * 0.99);
    // Check whether the green square ever moves off the screen
    // If so, reverse acceleration in that direction
    if (greenSquare.x < 0 || greenSquare.x > (app.screen.width - 100))
    {
      greenSquare.acceleration.x = -greenSquare.acceleration.x;
    }

    if (greenSquare.y < 0 || greenSquare.y > (app.screen.height - 100))
    {
      greenSquare.acceleration.y = -greenSquare.acceleration.y;
    }

    // If the green square pops out of the cordon, it pops back into the
    // middle
    if ((greenSquare.x < -30 || greenSquare.x > (app.screen.width + 30))
      || greenSquare.y < -30 || greenSquare.y > (app.screen.height + 30))
    {
      greenSquare.position.set((app.screen.width - 100) / 2, (app.screen.height - 100) / 2);
    }

    // If the mouse is off screen, then don't update any further
    if (app.screen.width > mouseCoords.x || mouseCoords.x > 0
      || app.screen.height > mouseCoords.y || mouseCoords.y > 0)
    {
      // Get the red square's center point
      const redSquareCenterPosition = new PIXI.Point(
        redSquare.x + (redSquare.width * 0.5),
        redSquare.y + (redSquare.height * 0.5),
      );

      // Calculate the direction vector between the mouse pointer and
      // the red square
      const toMouseDirection = new PIXI.Point(
        mouseCoords.x - redSquareCenterPosition.x,
        mouseCoords.y - redSquareCenterPosition.y,
      );

      // Use the above to figure out the angle that direction has
      const angleToMouse = Math.atan2(
        toMouseDirection.y,
        toMouseDirection.x,
      );

      // Figure out the speed the square should be travelling by, as a
      // function of how far away from the mouse pointer the red square is
      const distMouseRedSquare = distanceBetweenTwoPoints(
        mouseCoords,
        redSquareCenterPosition,
      );
      const redSpeed = distMouseRedSquare * movementSpeed;

      // Calculate the acceleration of the red square
      if(isStarted){
        redSquare.acceleration.set(
          Math.cos(angleToMouse) * redSpeed,
          Math.sin(angleToMouse) * redSpeed,
        );
      }
    }
    //check collision bullets
    for(var b=bullets.length-1;b>=0;b--){
      if(testForAABB(bullets[b], greenSquare)){
        console.log('hit')
        //hit effect
        greenSquare.tint = 0xFF0000;
        setTimeout(() => {
          //초기화
          greenSquare.tint = 0xFFFFFF;
        } , 100);
        app.stage.removeChild(bullets[b]);

        bullets.splice(b,1);
      }
    }
 /*   // If the two squares are colliding
    if (testForAABB(greenSquare, redSquare))
    {
      // Calculate the changes in acceleration that should be made between
      // each square as a result of the collision
      const collisionPush = collisionResponse(greenSquare, redSquare);
      // Set the changes in acceleration for both squares

      /!*redSquare.acceleration.set(
        (collisionPush.x * greenSquare.mass),
        (collisionPush.y * greenSquare.mass),
      );
      greenSquare.acceleration.set(
        -(collisionPush.x * redSquare.mass),
        -(collisionPush.y * redSquare.mass),
      );*!/

    }*/

    greenSquare.x += greenSquare.acceleration.x * delta;
    greenSquare.y += greenSquare.acceleration.y * delta;

    redSquare.x += redSquare.acceleration.x * delta;
    redSquare.y += redSquare.acceleration.y * delta;
  });


  var bullets = [];
  var bulletSpeed = 5;

  function shoot(rotation, startPosition){
    var bullet = new PIXI.Sprite(carrotTex);
    bullet.width = 50;
    bullet.height = 50;
    bullet.position.x = startPosition.x + 24;
    bullet.position.y = startPosition.y;
    bullet.rotation = rotation;
    app.stage.addChild(bullet);
    bullets.push(bullet);
  }

// Add to stage
  app.stage.addChild(redSquare, greenSquare);



  return (
    <div>

    </div>
  );
}

export default App;
